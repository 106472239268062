/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

// favicon
import faviconImg from "../assets/email_icon.png" // Tell Webpack this JS file uses this image

// import Header from "./header"
// import "./layout.css"
// import "./devices.min.css"
import "./original.css"
import "./custom.less"

class Layout extends React.Component {
  // ... constructor and toggleMenu from above
  constructor(props) {
    super(props)
    this.state = { isMenuOpen: false }
  }

  toggleMenu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    })
  }

  render() {
    const { children, data } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
            <Helmet>
              <meta charSet="utf-8" />
              <title>VantageSMS.com — SMS Marketing Made Easy</title>
              <meta
                name="description"
                // content="Promote your business and reach the customers that matter most, your own."
                content="Promote your business with text messaging to reach the customers that matter most, your own."
              />
              <meta
                name="keywords"
                content="VantageSMS, SMS, Text, Texting, Small, Business, Online, Texting, Messaging, Marketing, Campaign"
              />
              <meta name="theme-color" content="#2962FF" />
              {/* <meta name="theme-color" content="#01D8FD" /> */}
              <link rel="shortcut icon" type="image/png" href={faviconImg} />
              {/* <link rel="canonical" href="http://mysite.com/example" /> */}

              {/* Open Graph Tags */}
              <meta name="og:type" content="website" />
              <meta name="og:title" content="VantageSMS" />
              <meta name="og:url" content="https://vantagesms.com/" />
              <meta name="og:site_name" content="VantageSMS" />
              <meta
                name="og:image"
                content="https://firebasestorage.googleapis.com/v0/b/vantagesms-prod.appspot.com/o/public%2Fstripe_icon_3.png?alt=media&token=baef842f-886a-4c60-988a-9b68d6f711ae"
              />
              <meta
                name="og:description"
                content="Promote your business with text messaging to reach the customers that matter most, your own."
              />
              <link
                async
                href="https://fonts.googleapis.com/css?family=Open+Sans:400,700"
                rel="stylesheet"
              />
              <link
                async
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet"
              />

              {/* <link
                href="https://fonts.googleapis.com/css?family=Open+Sans:400,700"
                rel="shortcut icon"
              /> */}
            </Helmet>
            {/* HEADER */}
            <div className="header">
              <div className="content">
                <h1>
                  <Link to="/">VantageSMS</Link>
                </h1>
                <div className="mobileMenuIcon">
                  {/* <i
                    className="material-icons"
                    style={{ fontSize: "40px", marginTop: "2px" }}
                    onClick={e => {
                      this.toggleMenu()
                    }}
                  >
                    menu
                  </i> */}
                  <button
                    onClick={e => {
                      this.toggleMenu()
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ fontSize: "40px", marginTop: "2px" }}
                    >
                      {this.state.isMenuOpen ? "close" : "menu"}
                    </i>
                  </button>
                </div>
                {/* {this.state.isMenuOpen && (
                  <div className="">IS MENU OPEN!!</div>
                )} */}
                <ul
                  id="headerMenu"
                  // className={`desktopMenu `}
                  className={`desktopMenu ${
                    this.state.isMenuOpen ? "mobileMenuOpen" : ""
                  }`}
                >
                  {/* <li>
                <Link to="/login/">Login</Link>
              </li> */}
                  {/* <li className="">
                    <Link to="/samples/">Message Samples</Link>
                  </li> */}
                  <li className="mobileMenuItem">
                    <Link to="/samples/">Free Sample Messages</Link>
                  </li>
                  <li className="">
                    <Link to="/contact/">Contact</Link>
                  </li>
                  <li className="">
                    <Link to="/faq/">FAQ</Link>
                  </li>
                  <li className="">
                    <Link to="/pricing/">Pricing</Link>
                  </li>
                  <li className="">
                    <Link to="/features/">Features</Link>
                  </li>
                  <li className="">
                    <Link to="/process/">How it works</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pt68">
              {/* BODY */}
              <main>{children}</main>

              {/* FOOTER */}
              <div className="footer taCenter bb0">
                <div className="content">
                  <p>
                    <Link to="/">Home</Link>

                    <Link to="/process/">How it works</Link>

                    <Link to="/features/">Features</Link>

                    <Link to="/pricing/">Pricing</Link>

                    <Link to="/faq/">FAQ</Link>

                    <Link to="/contact/">Contact</Link>
                  </p>

                  <p>
                    <Link to="/terms/">Terms of Use</Link>

                    <Link to="/privacy/">Privacy Policy</Link>

                    <Link to="/use/">Acceptable Use</Link>
                  </p>
                  <p>
                    <Link to="/samples/">Free Message Samples</Link>
                  </p>
                  {/* <p>
                <button className="">Sign Up</button>
                <button className="">Login</button>
              </p> */}
                  <br />
                  {/* SOCIAL ICONS */}
                  {/* <p className="mb0">
                    <i className="material-icons">flight_takeoff</i>
                    <i className="material-icons">thumb_up</i>
                    <i className="material-icons">mail</i>
                  </p> */}
                  <p className="mt0">
                    {/* <Link to="/">VantageSMS.com</Link> */}
                    VantageSMS.com
                  </p>
                  <div className="flexContainer">
                    <div className="flexCard">
                      <h4>
                        <a href="mailto:hello@vantagesms.com">
                          hello@vantagesms.com
                        </a>
                        <br />
                        <a href="mailto:support@vantagesms.com">
                          support@vantagesms.com
                        </a>
                      </h4>
                    </div>
                    <div className="flexCard">
                      <h4>+1-855-908-4011</h4>
                    </div>
                    <div className="flexCard">
                      <h4>
                        7030 Woodbine Avenue, Suite 500,
                        <br />
                        Markham, ON, Canada
                        <br />
                        L3R 6G2
                      </h4>
                    </div>
                  </div>
                  {/* <br /> */}
                </div>
              </div>
              {/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
            </div>

            {/* <!-- Start of HubSpot Embed Code --> */}
            <script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js.hs-scripts.com/5819295.js"
            />
            {/* <!-- End of HubSpot Embed Code --> */}
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
