import { Link, navigate } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

// const EmailCatpure = ({ siteTitle }) => (

class EmailCatpure extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      //   ["bot-field"]: "",
      name: "",
    }
  }

  handleChange(e) {
    // console.log(`${e.target.name}: ${e.target.value}`)
    this.setState({ [e.target.name]: e.target.value })
  }

  isEmailValid(email) {
    // const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // https://www.w3resource.com/javascript/form/email-validation.php
    const mailFormat = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    )
    // const mailFormat = new RegExp("/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/")
    // const mailFormat = new RegExp(/^[^s@]+@[^s@]+.[^s@]+$/)
    // const mailFormat = new RegExp(/\S+@\S+\.\S+/)
    if (mailFormat.test(email)) {
      //   console.log("mail format test valid")
      return true
    } else {
      //   console.log("mail format test not valid")
      return false
    }
  }

  // netlify forms in react
  // https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
  handleSubmit = e => {
    e.preventDefault()
    if (!this.state.email) {
      alert("You forgot to enter your email!")
      return
    }
    if (!this.isEmailValid(this.state.email)) {
      alert("The email you entered is invalid :(")
      return
    }
    // return
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => {
        // alert("Success!")
        navigate("/success", {
          state: { email: this.state.email },
        })
      })
      .catch(error => alert(error))

    e.preventDefault()
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  render() {
    return (
      <>
        {/* <input
          type="email"
          name="email"
          value={this.state.email}
          placeholder="your.name@email.com"
          onChange={e => this.handleChange(e)}
        />
        <br />
        <Link to={`/start/`} state={{ email: this.state.email }}>
          <button className="">Get Started</button>
        </Link> */}

        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="name"
          onSubmit={e => this.handleSubmit(e)}
        >
          {/* <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" /> */}
          <p className="noHeight oHidden m0">
            <label>
              Don’t fill this out if you're human:
              <input
                name="name"
                value={this.state["name"]}
                onChange={e => this.handleChange(e)}
              />
            </label>
          </p>
          {/* <p className="mb0">
            <label>
              Your Name:{" "}
              <input type="text" name="name" placeholder="Full Name" />
            </label>
          </p> */}
          {/* <p style={{ marginTop: "-4px" }}>
            <label>
              Your Email:{" "}
              <input type="email" name="email" placeholder="you@email.com" />
            </label>
          </p> */}
          <p className="mt0 mb0">
            <input
              type="email"
              // placeholder="your@email.com"
              placeholder="Enter your email"
              name="email"
              value={this.state.email}
              onChange={e => this.handleChange(e)}
            />
          </p>
          <p className="mt0">
            <button type="submit">Get Started</button>
          </p>
        </form>
      </>
    )
  }
}
// )

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default EmailCatpure
