import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

// const IndexPage = () => (

class FaqPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openFaqId: "",
      openFaqs: [],
    }
  }

  handleChange(e) {
    // console.log(`${e.target.name}: ${e.target.value}`)
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {}

  faqArrow(faqId) {
    // console.log("faqArrow: ", faqId)
    const openFaqs = this.state.openFaqs
    // this.state.openFaqId !== faqId
    if (openFaqs.indexOf(faqId) === -1) {
      // console.log("faqArrow nomatch: ", faqId)
      return <i className="material-icons faqIcon">keyboard_arrow_down</i>
    } else {
      // console.log("faqArrow match: ", faqId)
      return <i className="material-icons faqIcon tgrey3">keyboard_arrow_up</i>
    }
  }

  faqToggle(faqId) {
    const openFaqs = this.state.openFaqs
    if (openFaqs.indexOf(faqId) === -1) {
      // console.log("faqId nomatch: ", faqId)
      openFaqs.push(faqId)
    } else {
      // console.log("faqId match: ", faqId)
      openFaqs.splice(openFaqs.indexOf(faqId), 1)
    }
    this.setState({
      openFaqs: openFaqs,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="FAQ" />
        {/* HERO */}
        <div className="hero1">
          <div className="content taCenter">
            <h1>FAQ</h1>
            {/* <p>A Fully Managed SMS Marketing Service.</p> */}
            {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
            {/* <hr /> */}
            {/* <p>A Fully Managed SMS Marketing Service</p> */}
            {/* <p>
          Have a text message you want to broadcast out?
          <br /> Just contact us and we take care of the rest!
        </p> */}
            {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
            <p>
              Can't find an answer? Please reach out to us at:
              <br />
              <a href="mailto:hello@vantagesms.com">hello@vantagesms.com</a>
            </p>
            {/* <hr />
                <p>Contact us and we'll handle it for you!</p>
                <p>
                  <input
                    type="email"
                    name=""
                    placeholder="your.name@email.com"
                  />
                  <br />
                  <button className="">Get Started</button>
                  <button className="">Yes, Contact Me</button>
                </p> */}
          </div>
        </div>

        {/* FAQ */}
        <div className="hero5">
          <div className="content">
            {/* <h1>FAQ</h1>
            <h2>
              Can't find an answer? Please reach out to us at:
              <br />
              <a href="mailto:hello@vantagesms.com">hello@vantagesms.com</a>
            </h2>
            <br /> */}
            <div className="flexContainer">
              {/* {this.setState({
                faqList: [
                  {
                    id: "ltc",
                    title: "Are there long term contracts?",
                    body: `No, this is an on demand service. We don't lock you into
                      long term agreements. You only pay for the broadcast
                      messages you send or receive.`,
                  },
                  {
                    id: "cat",
                    title: "xxx",
                    body: "yyy",
                  },
                  {
                    id: "aaa",
                    title: "xxx",
                    body: "yyy",
                  },
                ],
              })} */}
              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("ltc") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("ltc")}
              >
                <h3 className="pr40">
                  {this.faqArrow("ltc")}Are there long term contracts?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("ltc") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  No, this is an on demand service. We don't lock you into long
                  term agreements. You only pay for the broadcast messages you
                  send or receive.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("cat") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("cat")}
              >
                <h3 className="pr40">
                  {this.faqArrow("cat")}Can I cancel anytime?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("cat") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  Yes, simply send us an email saying you want to cancel. We'll
                  be sorry to see you go, but we'll get it sorted for you. Note
                  that you will be billed for your current month's usage at time
                  of cancellation.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("hpw") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("hpw")}
              >
                <h3 className="pr40">
                  {this.faqArrow("hpw")}How does your pricing work?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("hpw") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  You are only charged for the SMS, MMS, and Source Numbers used
                  to during your broadcast.
                  <br />
                  <br />
                  For example, if you have 1000 contacts and send a 2 part SMS
                  broadcast, we will invoice you about 1 week after your
                  broadcast for 2000 SMS Messages sent (1000 Contacts * 2 SMS
                  Parts) and 7 Source Numbers (1000 Contacts / 150 Contacts per
                  source number). Plus any inbound SMS/MMS you may have received
                  from your contacts.
                  <br />
                  <br />
                  We have a cost calculator on the{" "}
                  <Link to="/pricing/">pricing page</Link> to help estimate
                  pricing based on your contact list size and broadcast message
                  length.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("wsn") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("wsn")}
              >
                <h3 className="pr40">
                  {this.faqArrow("wsn")}What is a source number?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("wsn") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  A source number is the name of a phone number we use to send
                  out your message to your contact list. Normally we purchase 1
                  source number for every 150 contacts in your list to help
                  maximize delivery to your contacts.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("hlb") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("hlb")}
              >
                <h3 className="pr40">
                  {this.faqArrow("hlb")}How long does an SMS broadcast take?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("hlb") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  It normally takes 3 days to complete an SMS broadcast. We
                  purposefully slow the rate text messages are sent out to
                  maximize deliverability.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("clp") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("clp")}
              >
                <h3 className="pr40">
                  {this.faqArrow("clp")}Is my contact list kept private?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("clp") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  Yes, your contact list is never shared with anyone. It is kept
                  completely private and stored securely on a Google hosted
                  database with strong encryption and authentication protocols
                  in place. In addition, we have included in our Terms that we
                  will never share or sell your contact lists. We take your
                  privacy seriously and work to earn and keep your trust by
                  protecting the privacy and security of any information you
                  share with us.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("rmc") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("rmc")}
              >
                <h3 className="pr40">
                  {this.faqArrow("rmc")}How do I receive messages from my
                  contacts?
                </h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("rmc") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  Any inbound messages received from your broadcast will be
                  forwarded to your email along with the sender's contact
                  information.
                  <br />
                  <br />
                  Your reply to that email will be forwarded to the sender as an
                  SMS, or you can use our chat.vantagesms.com web app to chat
                  with your contacts in real time over SMS. Anyone replying with
                  the STOP keyword will be automatically opted out on your
                  behalf. We also include an opt out button in the forwarded
                  email if you need to manually opt out the sender.
                </p>
              </div>

              <div
                className={`card pointer noSelect ${
                  this.state.openFaqs.indexOf("was") !== -1 ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("was")}
              >
                <h3 className="pr40">{this.faqArrow("was")}What about spam?</h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("was") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >
                  Sending Spam (unsolicited commercial electronic message -
                  "CEM") is against the Law. You must comply with all laws,
                  regulations, rules and guidelines as set out in{" "}
                  <a
                    href="https://crtc.gc.ca/eng/com500/faq500.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Canada’s Anti-Spam Legislation
                  </a>{" "}
                  ("CASL").
                  <br />
                  <br />
                  You may, for example, obtain implied consent to send an CEM to
                  your customer or client if they made a purchase or lease of
                  goods, or services within the two-year period immediately
                  before the day on which the CEM was sent and providing that
                  you also provide identification information and provide an
                  unsubscribe mechanism in your CEM.
                  <br />
                  <br />
                  Any customer or client of yours that does not meet the
                  requirements of CASL, must be deleted and removed from your
                  contact list before you use the VantageSMS Services to send
                  any CEM's.
                  <br />
                  <br />
                  The information provided by VantageSMS on its website is for
                  general informational purposes only and is not intended to be
                  considered as providing legal advice, an interpretation of any
                  legislation or regulations, rules and guidelines, or a
                  substitute for specific legal advice from a lawyer.
                  {/* Sending spam messages is against the law. You must always
                  follow the laws regarding electronic communications in your
                  jurisdiction.
                  <br />
                  <br />
                  For example, in Canada, you must comply with the{" "}
                  <a href="http://www.fightspam.gc.ca/eic/site/030.nsf/eng/00304.html">
                    Canadian Anti Spam Legislation (CASL)
                  </a>{" "}
                  and cannot message your clients from more than two years ago
                  depending on implied or express consent. Any contacts you have
                  that do not meet the implied or express consent standards MUST
                  be removed from your contact list before sharing it with us.
                  If we suspect your message is spam, we will refuse to send it. */}
                </p>
              </div>

              {/* <div
                className={`card pointer noSelect ${
                  this.state.openFaqId === "uid" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("uid")}
              >
                <h3 className="pr40">{this.faqArrow("uid")}TITLE</h3>
                <p
                  className={`oHidden ${
                    this.state.openFaqs.indexOf("uid") !== -1
                      ? ""
                      : "noHeight m0"
                  }`}
                >BODY</p>
              </div> */}

              {/* <div
                className={`card pointer noSelect ${
                  this.state.openFaqId === "uid" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("uid")}
              >
                <h3 className="pr40">{this.faqArrow("uid")}TITLE</h3>
                {this.state.openFaqs.indexOf("uid") !== -1 && <p>BODY</p>}
              </div> */}

              {/* <div className="card">
                <h3>aaaa</h3>
                <p>bbbbb</p>
              </div> */}
            </div>
          </div>
        </div>

        {/* HERO FOOTER*/}
        <div className="heroFooter taCenter">
          <div className="content">
            <h1>Grow Your Sales</h1>
            {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
            <p>Reach the customers that matter most, your own.</p>
            <EmailCapture />
            {/* <p style={{ padding: "0px 20px" }}>
              <input type="email" name="" placeholder="your.name@email.com" />
              <br />
              <button className="">Get Started</button>
              <button className="">Yes, Contact Me</button>
              <button className="">Yes, Contact Me to Get Started</button>
            </p> */}
          </div>
        </div>
      </Layout>
    )
  }
}

// module.exports = {
//   IndexPage: IndexPage,
// }

export default FaqPage
